@import 'assets/styles/mixins.scss';

.head {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
}

.title {
  color: $gray-6;
  margin-bottom: 0;
  flex-shrink: 1;
  margin-right: rem(15);
}

.time {
  text-transform: uppercase;
  margin-left: auto;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: rem(12);
  color: $gray-5;
}

.content {
  margin-bottom: 0;
  color: $gray-4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
